<script>
import Layout from "../layouts/horizontal";
import PageHeader from "@/components/page-header";
import {
   
} from "@/state/helpers";


export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    
    return {
      title: this.$t("pages.tutorial.menuItem-title"),
      breadcrumbs: [
        {
          text: this.$t("pages.tutorial.menuItem-title"),
          active: true
        }
      ],
      videos: [
        // { displayName: 'C8 Studio Part 1 - Introduction', url: '', ready: false },
        { displayName: 'C8 Studio Part 2 - The Marketplace ', url: 'https://www.youtube.com/embed/JFxMpUmyJcU', ready: true },
        { displayName: 'C8 Studio Part 3 - Comparing Indices', url: 'https://www.youtube.com/embed/2UHB6A9K3BU', ready: true },
        { displayName: 'C8 Studio Part 4 - Combination Risk Parity Portfolio Allocation ', url: 'https://www.youtube.com/embed/rW3XqFg3MNg', ready: true },
        { displayName: 'C8 Studio Part 5 - Combination Tactical Asset Allocation Crypto', url: 'https://www.youtube.com/embed/TQrnDFjQeUw', ready: true },
        { displayName: 'C8 Studio Part 6 - Combination Tactical Asset Allocation Risk Premia', url: 'https://www.youtube.com/embed/LyaffLCs6a8', ready: true },
        // { displayName: 'C8 Studio Part 7 - Execution', url: '', ready: false },
      ]
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="container">
      <div class="row">
        <div class="col-md-6 ">
          <div class="card cardc8 m-2">
            <div class="embed-responsive embed-responsive-16by9" style="position: relative; border-radius: 12px 12px 12px 12px; overflow: hidden">
              <img src="@/assets/images/Tutorial_Part1_NoVideo.png" class="embed-responsive-item"/>
            </div>
          </div>
        </div>
        <div class="col-md-6 "  v-for="(v, $index) in videos" :key="$index">
          <div class="card cardc8 mb-4">
            <div class="embed-responsive embed-responsive-16by9" style="position: relative; border-radius: 12px 12px 12px 12px; overflow: hidden">
              <iframe class="embed-responsive-item" :src="v.url" :title="v.displayName"
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>
        <div class="col-md-6 ">
          <div class="card cardc8 mb-4">
            <div class="embed-responsive embed-responsive-16by9" style="position: relative; border-radius: 12px 12px 12px 12px; overflow: hidden">
              <img src="@/assets/images/Tutorial_Part2_NoVideo.png" class="embed-responsive-item"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>